import { useIntl } from 'react-intl';
import React from 'react'

export function anatomySpecialityFilterOptions() {
    const intl = useIntl();
    return [
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.regions' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.systems' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.complete' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.sections' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.allergy' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.cardiology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.odontology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.dermatology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.endocrinology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.gastroenterology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.infectious' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.nephrology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.neurology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.obstetrics' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.oncology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.ophthalmology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.orthopedics' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.otorhinolaryngology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.pediatrics' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.pneumology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.rheumatology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.AnatomySpeciality_filter.urology' })
    ];
}

export function typeFilterOptions() {
    const intl = useIntl();
    return [
        intl.formatMessage({ id: 'utils.UtilsConstants.TypeFilter_filter.anatomy' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.TypeFilter_filter.disease' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.TypeFilter_filter.physology' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.TypeFilter_filter.procedure' })
    ];
}

export function levelOfDetailFilterOptions() {
    const intl = useIntl();
    return [
        intl.formatMessage({ id: 'utils.UtilsConstants.LevelOfDetail_filter.gross' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.LevelOfDetail_filter.tissue' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.LevelOfDetail_filter.cellular' }),
        intl.formatMessage({ id: 'utils.UtilsConstants.LevelOfDetail_filter.molecular' })
    ];
}

export function getMaxScoreQuestionnaire(scoreType: null | 'PHQ-9' | 'EUROQOL-5D' | 'PITTSBURG' | 'YESAVAGE') {
    switch (scoreType) {
        case 'PHQ-9':
            return 27;
        case 'EUROQOL-5D':
            return null;
        case 'PITTSBURG':
            return null;
        case 'YESAVAGE':
            return null;
        default:
            return null;
    }
}

export const numberOfSubcriptionsInLeftMenu = 4;