import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Button, Chip, Collapse, ListItem, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
    User as UserIcon
} from 'react-feather';
import { WebSocketProvider } from 'src/contexts/WebSocketSFUContext';
import useWebSocket from 'src/hooks/useWebSocket';


interface NavItemProps {
    children?: ReactNode;
    className?: string;
    depth: number;
    href?: string;
    icon?: any;
    info?: any;
    open?: boolean;
    title: string;
    id?: string;
    image?: string;
    onClick?: any;
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium
            }
        }
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.primary.main
        }
    },
    avatar: {
        height: '1.5rem',
        width: '1.5rem',
        marginRight: theme.spacing(1)
    },
    vrProgramChip: {
        backgroundColor: theme.palette.action.active,
        color: theme.palette.common.white,
        padding: theme.spacing(0.5)
    }
}));

const NavItem: FC<NavItemProps> = ({
    children,
    className,
    depth,
    href,
    id,
    icon: Icon,
    info: Info,
    open: openProp,
    title,
    image,
    onClick,
    disabled,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(openProp);
    const { users } = useWebSocket();
    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const reloadPageIfNeeded = (href: string): void => {
        if (href === window.location.pathname) {
            window.location.reload();
        }
    };

    let paddingLeft = 16;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                onClick={onClick}
                {...rest}
            >
                <Button
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    {image &&
                        <Avatar
                            className={classes.avatar}
                            src={image}
                        />
                    }
                    <span className={classes.title}>
                        {title}
                    </span>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={open}>
                    {children}
                </Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            onClick={onClick}
            {...rest}
        >
            <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                component={RouterLink}
                style={style}
                to={href}
                disabled={disabled}
                onClick={() => reloadPageIfNeeded(href)}
            >
                {Icon && (
                    <Icon
                        className={classes.icon}
                        size="20"
                    />
                )}
                {image &&
                    <Avatar
                        className={classes.avatar}
                        src={image}
                    />
                }
                <span className={classes.title}>
                    {title}
                </span>
                {Info && <Info />}
                {title.includes('VR') && users.length > 0 &&
                    <Chip
                        label={users.length}
                        variant='filled'
                        className={classes.vrProgramChip}
                        icon={
                            <SvgIcon fontSize='small'>
                                <UserIcon color="white" />
                            </SvgIcon>
                        }
                        size='small'
                    />
                }
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
    open: false
};

export default NavItem;
