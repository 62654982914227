import React, {
    createContext,
    useEffect,
    useReducer,
    useState
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import useSettings from 'src/hooks/useSettings';
import { deleteKalturaPlaylistPlayerIdFromLocalStorage, deleteKalturaVideoPlayerIdFromLocalStorage, getAndStoreKalturaPlaylistPlayerId, getAndStoreKalturaVideoPlayerId } from 'src/views/utils/Utils';

interface AuthState {
    isInitialised: boolean;
    isAuthenticated: boolean;
    user: any | null
}

interface AuthContextValue extends AuthState {
    method: 'JWT',
    login: (email: string, password: string, organization_id: number) => Promise<void>;
    loginById: (id: number, password: string) => Promise<void>;
    logout: () => void;
    register: (
        name: string,
        last_name: string,
        email: string,
        mobile: string,
        medical_number: string,
        medical_classification: number[],
        organization_id: number,
        password: string) => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitialiseAction = {
    type: 'INITIALISE';
    payload: {
        isAuthenticated: boolean;
        user: any
    };
};

type LoginAction = {
    type: 'LOGIN';
    payload: {
        user: any
    };
};

type LoginByIdAction = {
    type: 'LOGINBYID';
    payload: {
        user: any
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type RegisterAction = {
    type: 'REGISTER';
    payload: {
        user: any
    };
};

type Action =
    | InitialiseAction
    | LoginAction
    | LoginByIdAction
    | LogoutAction
    | RegisterAction;

const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null
};

const isValidToken = (accessToken: string): boolean => {
    if (!accessToken) {
        return false;
    }

    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.clear();
        delete axios.defaults.headers.common.Authorization;
    }
};

const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'INITIALISE': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isInitialised: true,
                isAuthenticated,
                user
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGINBYID': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }
        case 'REGISTER': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    loginById: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const login = async (email: string, password: string, organization_id: number) => {
        const response = await axios.post<{ accessToken: string, home_page: string }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signin/medic', { email, password, organization_id });
        const { accessToken, home_page } = response.data;
        window.localStorage.setItem('digimevo_home_page', home_page);

        setSession(accessToken);
        const user = await prepareUser(accessToken);

        localStorage.setItem('showNavBar', 'true');
        localStorage.setItem('showTopBar', 'true');
        localStorage.setItem('alternativeLogout', 'false');
        localStorage.setItem('showPatientNavigablePath', 'true')
        localStorage.setItem('hide_send_prescription', 'true')
        getAndStoreKalturaVideoPlayerId()
        getAndStoreKalturaPlaylistPlayerId()
        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
    };

    const loginById = async (professional_medic_id: number, password: string) => {
        const response = await axios.post<{ accessToken: string, home_page: string }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signin/medic/by/id', { "id": professional_medic_id, password });
        const { accessToken, home_page } = response.data;
        window.localStorage.setItem('digimevo_home_page', home_page);

        setSession(accessToken);
        const user = await prepareUser(accessToken);

        localStorage.setItem('showNavBar', 'true');
        localStorage.setItem('showTopBar', 'true');
        localStorage.setItem('alternativeLogout', 'false');
        localStorage.setItem('showPatientNavigablePath', 'true')
        localStorage.setItem('hide_send_prescription', 'true')
        getAndStoreKalturaVideoPlayerId()
        getAndStoreKalturaPlaylistPlayerId()
        dispatch({
            type: 'LOGINBYID',
            payload: {
                user
            }
        });
    }

    const prepareUser = async (
        accessToken: string
    ) => {
        let config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        const user = (await axios.get(process.env.REACT_APP_SERVER_URL + '/auth/1.0/me', config)).data;

        if (user.avatar === undefined || user.avatar === null || user.avatar === "") {
            user.avatar = "/static/logo_avatar.jpg";
        }
        if (user.mobile === undefined || user.mobile === null) {
            user.mobile = "";
        }
        if (user.medical_number === undefined || user.medical_number === null) {
            user.medical_number = "";
        }
        if (user.language === undefined || user.language === null) {
            user.language = "es"
        }
        localStorage.setItem('user', JSON.stringify(user));
        window.dispatchEvent(new Event('userUpdated'));
        return user;
    }

    const logout = () => {
        localStorage.setItem('showNavBar', 'true');
        localStorage.setItem('showTopBar', 'true');
        localStorage.setItem('showPatientNavigablePath', 'true')
        localStorage.setItem('hide_send_prescription', 'true')
        localStorage.removeItem('user')
        window.dispatchEvent(new Event('userUpdated'));
        deleteKalturaVideoPlayerIdFromLocalStorage()
        deleteKalturaPlaylistPlayerIdFromLocalStorage()
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    const register = async (
        params
    ) => {
        const response = await axios.post<{ accessToken: string; home_page: string; }>(process.env.REACT_APP_SERVER_URL + '/auth/1.0/signup/medic', params);
        const { accessToken, home_page } = response.data;
        window.localStorage.setItem('accessToken', accessToken);
        window.localStorage.setItem('digimevo_home_page', home_page);

        localStorage.setItem('showNavBar', 'true');
        localStorage.setItem('showTopBar', 'true');
        localStorage.setItem('showPatientNavigablePath', 'true')
        localStorage.setItem('hide_send_prescription', 'true')
        getAndStoreKalturaVideoPlayerId()
        getAndStoreKalturaPlaylistPlayerId()
        const user = await prepareUser(accessToken);

        dispatch({
            type: 'REGISTER',
            payload: {
                user
            }
        });
    };

    useEffect(() => {
        const initialise = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);
                    const user = await prepareUser(accessToken);

                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            user: user
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: null

                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                loginById,
                logout,
                register
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;