import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Box, SvgIcon, Button, Menu, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Share2 as ShareIcon,
    Copy as CopyIcon,
    Download as DownloadIcon
} from 'react-feather';
import LinkIcon from '@mui/icons-material/Link';

import { useSnackbar } from 'notistack';
import { Media } from 'src/types/media';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { getWSConfig } from '../utils/Utils';
import { LoadingButton } from '@mui/lab';

export interface VideoPlayerDialogShareProps {
    channelViewId?: number;
    video: Media;
}

const useStyles = makeStyles((theme) => ({
    share: {
        paddingTop: "2.5rem",
        paddingBottom: "2.5rem",
        float: 'right',
    },
    menuItem: {
        width: '10rem',
    }
}));

const VideoPlayerDialogShare: FC<VideoPlayerDialogShareProps> = ({
    channelViewId, video
}) => {

    const classes = useStyles();
    const shareRef = useRef<HTMLButtonElement | null>(null);
    const [openShareMenu, setOpenShareMenu] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(true)
    const config = getWSConfig();

    const copyURL = (): void => {
        if ((location.pathname).split('=')[1] != null) {
            navigator.clipboard.writeText("www." + (location.pathname).split('=')[1] + "/" + (video.kaltura_video_id || video.entry_id));
        }
        else navigator.clipboard.writeText(window.location.origin + "/channelView/" + channelViewId + "/" + (video.kaltura_video_id || video.entry_id));
        enqueueSnackbar(intl.formatMessage({ id: 'VideoPlayer.VideoPlayerDialogShare.url_copied' }), { variant: 'success' });
        setOpenShareMenu(false);
    }

    return (
        <>
            {channelViewId && video &&
                <Box mt={3} justifyContent="space-between" display="flex">
                    <Button
                        variant="outlined"
                        ref={shareRef}
                        onClick={() => setOpenShareMenu(true)}
                        startIcon={
                            <SvgIcon fontSize="small">
                                <ShareIcon />
                            </SvgIcon>
                        }
                    >
                        {intl.formatMessage({ id: 'VideoPlayer.VideoPlayerDialogShare.share' })}
                    </Button>
                    <Menu
                        anchorEl={shareRef.current}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        open={openShareMenu}
                        onClose={() => setOpenShareMenu(false)}
                    >
                        <MenuItem
                            key="ViewInfo"
                            onClick={copyURL}
                            className={classes.menuItem}
                        >
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary={"URL"} />
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                <CopyIcon />
                            </SvgIcon>
                        </MenuItem>
                    </Menu>
                </Box>
            }
        </>
    );
};

export default VideoPlayerDialogShare;

