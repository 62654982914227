import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, ListSubheader, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Clipboard as ClipboardIcon,
    Users as UsersIcon,
    List as ListIcon,
    Video as VideoIcon,
    Box as BoxIcon,
    ChevronDown as ChevronDownIcon,
    ChevronUp as ChevronUpIcon,
    CheckCircle as CheckCircleIcon,
    Home as HomeIcon,
    FileText as FileTextIcon,
    CheckSquare as CheckSquareIcon,
    Heart as HeartIcon,
    Grid as AllContentIcon,
} from 'react-feather';
import NavItem from './NavItem';
import axios from 'axios';
import { getOrganizationTag, getWSConfig } from 'src/views/utils/Utils';
import { numberOfSubcriptionsInLeftMenu } from 'src/views/utils/UtilsConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';

interface NavBarProps {
    onMobileClose: () => void;
    openMobile: boolean;
    displace?: boolean;
}

interface Item {
    href?: string;
    icon?: ReactNode;
    info?: ReactNode;
    items?: Item[];
    title: string;
    image?: string;
    disabled?: boolean;
}

interface Section {
    items: Item[];
    subheader: string;
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
        zIndex: theme.zIndex.appBar - 1
    },
    desktopDrawerWithoutTopBar: {
        width: 256,
        top: 0,
        height: 'calc(100% - 0px)',
        zIndex: theme.zIndex.appBar - 1
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    menuItem: {
        marginBottom: '0.5rem',
        '& a': {
            paddingLeft: '0rem',
            '& span': {
                '& span': {
                    marginLeft: '1.5rem',
                }
            }
        }
    },
    menuItemSeeMoreSeeLess: {
        marginBottom: '0.5rem',
        '& a': {
            paddingLeft: '0rem',
            '& span': {
                '& span': {
                    marginLeft: '1.5rem',
                    color: theme.palette.text.secondary,
                },
                '& svg': {
                    color: theme.palette.text.secondary + "!important",
                },
            }
        }
    },
    invisible: {
        display: 'none'
    },
    leftPadding: {
        paddingLeft: '0.5rem'
    },
    subheader: {
        fontSize: '0.875'
    }

}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile, displace }) => {
    const classes = useStyles();
    const intl = useIntl();

    const sections: Section[] = [
        {
            subheader: '',
            items: [
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.homepage' }),
                    icon: HomeIcon,
                    href: '/app/homepage',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.patients' }),
                    icon: UsersIcon,
                    href: '/app/patients',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.prescriptions' }),
                    icon: ClipboardIcon,
                    href: '/app/prescriptions',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.clinicalResources' }),
                    icon: AllContentIcon,
                    items: [
                        {
                            title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.models_3D' }),
                            icon: BoxIcon,
                            href: '/app/3D',
                        },
                        {
                            title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.etrajectories' }),
                            icon: FileTextIcon,
                            href: '/app/trajectories',
                        },

                        {
                            title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.health_navigator' }),
                            icon: HeartIcon,
                            href: '/app/healthNavigator',
                        },
                        {
                            title: getOrganizationTag('layouts.DashboardLayout.NavBar.index.channels'),
                            icon: ListIcon,
                            href: '/app/channels',
                        },
                        {
                            title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.questionnaires' }),
                            icon: CheckSquareIcon,
                            href: '/app/questionnaires',
                        },
                        {
                            title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.vrprograms' }),
                            icon: VrpanoOutlinedIcon,
                            href: '/app/vrprograms',
                        },
                    ]
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.econsents' }),
                    icon: CheckCircleIcon,
                    href: '/app/econsents',
                }
            ]
        }
    ];

    const myContentSection: Section[] = [
        {
            subheader: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.myContent' }),
            items: [
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.library' }),
                    icon: VideoIcon,
                    href: '/app/myContent',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.favorites' }),
                    icon: HeartIcon,
                    href: '/app/favorites',
                    disabled: true,
                },
            ]
        }
    ];


    const location = useLocation();
    const [subscriptionsInLeftMenu, setSubscriptionsInLeftMenu] = useState<Item[]>([]);
    const [seeAllMySubscriptions, setSeeAllMySubscriptions] = useState<Boolean>(false);
    const config = getWSConfig();

    const seeLess = (): void => {
        setSeeAllMySubscriptions(false);
    };

    const seeMore = (): void => {
        setSeeAllMySubscriptions(true);
    };

    function renderNavItems(
        items: Item[],
        pathname: string,
        maximumElementsToShow: number,
        depth: number = 0,
        classes) {
        return (
            <List disablePadding>
                {items.reduce(
                    (acc, item) => reduceChildRoutes(acc, pathname, item, depth, maximumElementsToShow, classes),
                    []
                )}
            </List>
        );
    }

    function reduceChildRoutes(
        acc: any[],
        pathname: string,
        item: Item,
        depth: number,
        maximumElementsToShow: number,
        classes) {
        const key = item.title + depth;

        if (item.items) {
            const open = matchPath(pathname, {
                path: item.href,
                exact: false
            });

            acc.push(
                <NavItem
                    depth={depth}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    open={Boolean(open)}
                    title={item.title}
                    className={classes.menuItem}
                    image={item.image}
                    disabled={item.disabled}
                >
                    {renderNavItems(
                        item.items,
                        pathname,
                        maximumElementsToShow,
                        depth + 1,
                        classes)}
                </NavItem>
            );
        } else {
            const lengthArrayAcc = acc.length;
            if (lengthArrayAcc < maximumElementsToShow) {
                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                        className={classes.menuItem}
                        image={item.image}
                        disabled={item.disabled}
                    />
                );
            }
            if (lengthArrayAcc === maximumElementsToShow) {
                acc.push(
                    <NavItem
                        depth={depth}
                        href="#"
                        onClick={seeMore}
                        icon={ChevronDownIcon}
                        key="seeMore"
                        title={intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.mysubscriptions.seeMore' })}
                        className={seeAllMySubscriptions ? classes.invisible : classes.menuItemSeeMoreSeeLess}
                    />
                );

                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                        className={seeAllMySubscriptions ? classes.menuItem : classes.invisible}
                        image={item.image}
                        disabled={item.disabled}
                    />
                );

                acc.push(
                    <NavItem
                        depth={depth}
                        href="#"
                        onClick={seeLess}
                        icon={ChevronUpIcon}
                        key="seeLess"
                        title="Ver menos"
                        className={seeAllMySubscriptions ? classes.menuItemSeeMoreSeeLess : classes.invisible}
                    />
                );
            }
            if (lengthArrayAcc > maximumElementsToShow) {
                acc.pop();
                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                        className={seeAllMySubscriptions ? classes.menuItem : classes.invisible}
                        image={item.image}
                        disabled={item.disabled}
                    />
                );
                acc.push(
                    <NavItem
                        depth={depth}
                        href="#"
                        onClick={seeLess}
                        icon={ChevronUpIcon}
                        key="seeLess"
                        title={intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.mysubscriptions.seeLess' })}
                        className={seeAllMySubscriptions ? classes.menuItemSeeMoreSeeLess : classes.invisible}
                        disabled={item.disabled}
                    />
                );
            }
        }

        return acc;
    }

    const getMyChannels = (): void => {
        axios.get(process.env.REACT_APP_SERVER_URL + '/channel/1.0/get/my/channels', config)
            .then(function (response) {
                if (response && response.data) {
                    if (process.env.REACT_APP_BUILD_ENV === 'local') {
                        console.log("receiving my channels" + response.data.toString());
                    }
                    let channel_list = response.data;
                    let index = channel_list.findIndex(function (channel) {
                        return channel.channel_id === 0;
                    })
                    if (index !== -1) channel_list.splice(index, 1);

                    let itemsToAppearInLeftMenu: Item[] = [];
                    channel_list.map(channel => itemsToAppearInLeftMenu.push({
                        title: channel.name, image: channel.logo,
                        href: '/app/channel/' + channel.channelViewId
                    }));
                    setSubscriptionsInLeftMenu(itemsToAppearInLeftMenu);
                }
            })
            .catch(function (error) {
                if (process.env.REACT_APP_BUILD_ENV === 'local' && error && error.response && error.response.data) {
                    console.error(error.response.data)
                }
            })
    }

    useEffect(() => {
        getMyChannels();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            mt={4}
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box p={2} className={classes.leftPadding}>
                    {sections.map((section) => (
                        <List
                            key={section.subheader}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    {section.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems(
                                section.items,
                                location.pathname,
                                100,
                                0,
                                classes)
                            }
                        </List>
                    ))}
                    {myContentSection.map((myContentSection) => (
                        <List
                            key={myContentSection.subheader}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    <Typography variant='overline' pl={'1rem'}>
                                        {myContentSection.subheader}

                                    </Typography>
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems(
                                myContentSection.items,
                                location.pathname,
                                100,
                                0,
                                classes)
                            }
                        </List>

                    ))}
                    <List
                        key="channels"
                        subheader={(
                            <ListSubheader
                                disableGutters
                                disableSticky
                            >
                                <Typography variant='overline' pl={'1rem'}>
                                    <FormattedMessage id="layouts.DashboardLayout.NavBar.index.mysubscriptions.name" />
                                </Typography>
                            </ListSubheader>
                        )}
                    >
                        {renderNavItems(
                            subscriptionsInLeftMenu,
                            location.pathname,
                            numberOfSubcriptionsInLeftMenu,
                            0,
                            classes)
                        }
                    </List>
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return <>
        <Hidden lgUp>
            <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            {displace &&
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawerWithoutTopBar }}
                    open
                    variant="persistent"
                >
                    {content}

                </Drawer>
            }:
            {!displace &&
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            }
        </Hidden>
    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
