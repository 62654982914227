import axios from 'axios';
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';

interface LogoProps {
    [key: string]: any;
    height?: number;
    width?: number;
    organization_id?: number;
}

const Logo: FC<LogoProps> = (props, height?, width?, organization_id?) => {
    const professional = JSON.parse(localStorage.getItem('user'));
    const [organizationLogo, setOrganizationLogo] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!organizationLogo) {
            let params = {
                organization_id: organization_id || professional?.organization_id || 0,
            };
            axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/logo', params)
                .then(function (response) {
                    if (response && response.data) {
                        setOrganizationLogo(response.data)
                    }
                    else {
                        setOrganizationLogo('/static/logo.png')
                    }
                })
                .catch(function (error) {
                    setOrganizationLogo('/static/logo.png')
                })
            setLoading(false)
        }
        else {
            setLoading(false);
        }

    }, []);

    if (loading) {
        return null;
    }
    else {
        return (
            <img
                style={{ cursor: 'pointer', width: '10rem' }}
                height={height}
                width={width}
                alt="Logo"
                src={organizationLogo}
                {...props}
            />
        );
    }
}

export default Logo;
